import type { ProductModel } from '@/domain/product.model';
import { useRequestStore } from '@/store/request.store';
import { useRouter } from 'vue-router';

export const UseGoToInform = () => {
  const router = useRouter();
  const RequestStore = useRequestStore();

  const goToInform = (typeInform: ProductModel['product_slug']) => {
    RequestStore.setFormTypeInforme(typeInform);
    router.push({ name: 'InformeRequestView' });
  };

  return {
    goToInform
  };
};
