import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a697802"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "nav-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_routerLink = _resolveComponent("routerLink")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_routerLink, { to: {name: 'Home'} }, {
          default: _withCtx(() => [
            _createVNode($setup["AppWebName"])
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: "nav-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showMenuMobile = !$setup.showMenuMobile))
        }, [
          _createVNode($setup["AppCustomIcon"], { nameIcon: "mdiMenu" })
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["wrapper-menu", { active: $setup.showMenuMobile }])
        }, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.goToInform('informe-completo')))
            }, "Informe Completo"),
            _createElementVNode("li", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.goToInform('informe-basico')))
            }, "Informe Basico")
          ])
        ], 2)
      ])
    ])
  ]))
}