import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import VueGtag from 'vue-gtag-next';
import gads from '@/plugins/gads';

import './style.scss';

import createContainerRepositories from './repositories/repository.container';

const pinia = createPinia();
const app = createApp(App);

app
  .use(createContainerRepositories)
  .use(pinia)
  .use(router)
  .use(gads, {
    property_id: 'AW-10957152527',
    conversion_track_id: 'AW-10957152527/vrG8CLfJg9EDEI_C4-go'
  })
  // .use(VueGtag, {
  //   property: { id: 'G-7PDFX8TB4E' },
  //   router
  // })
  .mount('#app');
