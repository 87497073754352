import { App } from 'vue';
import { MiscRepository } from './misc/misc.repository';
import { ProductsRepository } from './products/products.repository';
import { RequestsRepository } from './requests/requests.repository';

export default {
  install: (app: App) => {
    app.provide('ProductsRepository', new ProductsRepository());
    app.provide('RequestsRepository', new RequestsRepository());
    app.provide('MiscRepository', new MiscRepository());
  }
};
